import React, { useEffect, useState, useContext } from "react";
import axios from '../../util/axios'
import { scrollToTop } from "../../util/functions";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { Button, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ActiveContext from "../../Context/Context";
import Layout from "../../Components/TopNav/Topnav";
import Loading from "../../Components/Loading/loading.component";
import {toast} from "react-toastify";
import {TabsControllerContext} from "../../Context/Tabs/TabsProvider";

const useStyles = makeStyles(()=>{ return {
  fontFamilyAlmarai:{
    fontFamily:'Almarai-semi !important'
  },
  backbtn:{
    color: '#637381',
    fontSize: '16px !important',
    letterSpacing: 0,
    lineHeight: '20px',
    marginBottom:'1%',
    marginTop:'16px',
    '&:hover':{
      backgroundColor:'#F6F6F7'
    }
  },
  alignLeft:{
    marginRight: '95%'
  },
  alignRight:{
    marginLeft: '95%'
  }
}});
const BookingWidget = () => {
    const query = new URLSearchParams(document.location.search);
    const navigate = useNavigate()
    const classes = useStyles();
    const firstName = query.get('first_name');
    const lastName = query.get('last_name');
    const email = query.get('email');
    const phone = query.get('phone');
    const usiId = query.get('usiId');
    const {i18n} = useTranslation();
    const [type, setType] = useState("");
    const [appointmentType, setAppointmentType] = useState('');
    const [bookingId, setBookingId] = useState('');
    const [loading, setLoading] = useState(true);
    const [isReschedule, setIsReschedule] = useState(false)
    const [prefilledUrl, setPrefilledUrl] = useState("")
    const { clinicData } = useContext(ActiveContext);  
    const { pendingBooking } = useContext(TabsControllerContext);
    const closeScheduleReschedulePage = ()=>{
      navigate(`/dashboard`);
    }

    function openInNewTab(url) {
      navigate(`/en/scan-cnfm-loading`);
      window.open(url, "_blank").focus();
    }
    
    const fetchClinicData = async () => {
        setLoading(true);
        if (clinicData) {
            const scheduleState =
                window.location.search.includes("proc_schedule=true");
            let body = {
                clinic_id: Cookies.get("clinic_id"),
                pendingBookingId: pendingBooking.id,
                user_scan_info_id: usiId,
                procedure_schedule: scheduleState,
            };
            try {
                const response = await axios.post(`admin/v1/clinic/reschedule`, body);
                if (response.status === 200) {
                    setType(response.data.type);
                    setAppointmentType(response.data.appointment_type);
                    setBookingId(response.data.booking_id);
                    window.addEventListener &&
                    window.addEventListener(
                        "message",
                        function (event) {
                            if (
                                event.origin ===
                                clinicData?.data?.clinic_info.youcanbookme_link
                            ) {
                                document.getElementById("ycbmiframenabih").style.height =
                                    event.data + "px";
                            }
                        },
                        false,
                    );

                    if (window !== "undefined") {
                        try {
                            window.scroll({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                            });
                        } catch (error) {
                            scrollToTop(0);
                        }
                    }
                }
            } catch (e) {
                console.log("error in checking booking status", e);
                toast.error("Error in checking booking status", 3000);
            } finally {
                setLoading(false);
            }
        }
    };

  useEffect(() => {
    void fetchClinicData();
  }, [clinicData]);
  
  const lang = 'en'
  const host = process.env.REACT_APP_HOST
  const port = process.env.REACT_APP_PORT

  useEffect(()=>{
    if(type && appointmentType) {
      if(type === 'reschedule') {
        if(bookingId){
          let url = `&HOST=${host}&LANG=${lang}&PORT=${port}&b=${bookingId}&or=${bookingId}&TYPE=${appointmentType}&PENDINGBOOKINGID=${pendingBooking.id}`;
          openInNewTab(`${clinicData?.data?.clinic_info?.youcanbookme_link}/?${url}`)
        }
      } else if (type === 'rebooking') {
        let url = `&HOST=${host}&LANG=${lang}&PORT=${port}&FNAME=${firstName}&LNAME=${lastName}&EMAIL=${email}&PHONE=${phone}&TYPE=${appointmentType}&PENDINGBOOKINGID=${pendingBooking.id}`;
        setPrefilledUrl(url);
      }
    }
  }, [bookingId, type, appointmentType])
    
    if (loading) {
      return (
        <Layout>
            <Loading />
        </Layout>
      );
    }

  return (
    !loading && <Layout>
      <Button onClick={closeScheduleReschedulePage} className={`${classes.backbtn} ${i18n.language == 'ar' ? `${classes.fontFamilyAlmarai} ${classes.alignLeft}` : `${classes.alignRight}`}`} startIcon={
            <CloseIcon  style={{ fontSize: '25px', fontWeight:'bold', textAlign:  'center' }}/>
      }></Button>
          <section>
                    {!isReschedule && prefilledUrl &&<iframe
                      src={`${clinicData?.data?.clinic_info?.youcanbookme_link}/?noframe=true&skipHeaderFooter=true${prefilledUrl}`}
                      id="ycbmiframenabih"
                      style={{
                        width: "100%",
                        height: '100rem',
                        border: "0px",
                        backgroundColor: "transparent",
                      }}
                      frameBorder="0"
                      allowtransparency="true"
                    />}
      </section>
    </Layout>
  );
};

export default BookingWidget;
 
