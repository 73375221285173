import React, { useState, useCallback,useEffect } from "react";
import { Avatar, Box, Button, makeStyles, Tooltip, Typography, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import axios from '../../util/axios'
import { useTranslation } from "react-i18next";
import { clinicImpersonated } from "../../util/functions";

const useStyles = makeStyles(()=>{ return {
  fontFamilyAlmarai:{
    fontFamily:'Almarai-semi !important'
  },
  fontFamilyEina: {
    fontFamily: "Eina-semi !important",
  },
  root: {
      backgroundColor:"rgb(246, 246, 247)",
  },
  loadingBox:{
      margin: "8% auto",
      textAlign: "center",
      padding : 30
  },
  loading: {
      textAlign: 'center',
      margin: "0 auto",
      color: '#ff2c46'
  },
  btn:{
    position:"absolute",
    top: "0.7rem",
    right: '0.1rem'
  },
  wrapper:{
    display:'flex',
    flex:1,
    gap:"1.25rem"
  },
  informationStyles:{
    height:'167px',
    display:'flex',
    flexDirection:'column', 
    justifyContent:"space-between"
  },
  bold:{
    fontWeight:'bold',
  },
  thin:{
    fontWeight:'500',
  },
  nameStyle:{
    color:'#212B36', 
    lineHeight:'2rem',
  },
  typoColor:{
    color:'#637381'
  },
  fontSizeBG:{
    fontSize:'1.75rem !important'
  },
  fontSizeMD:{
    fontSize:'1.125rem !important'
  },
  fontSizeSM:{
    fontSize:'0.875rem !important'
  },
  avatar: {
    backgroundColor: "white",
    width:'100%', 
    height:'100%',
    color: "#ff2c46"
  },
  reviewBoxWrapper:{
    display:'flex',
    width:'100%',
    flexWrap:'wrap',
    gap: '5px'
  },
  review:{
    marginTop:'auto',
  },
  couponWrapper:{
    display: 'flex',
    marginTop:'auto',
    gap: '5px',
  },
  selectReview:{
    marginTop:'auto',
    minWidth: '10%',
    marginBottom: '0',
  },
  phoneNbAr:{
    direction: 'initial',
    textAlign: 'end',
  }
}});

function Profile(props) {
  const { profileResponse , avatar, couponCodeOptions, setCoupon, coupon, id } = props;
  const { t, i18n } = useTranslation()
  const classes = useStyles();
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const [buttonText, setButtonText] = useState(t('Save'));
  const saveUserCoupon = async() => {
    let userCoupon = {
      user_id: id,
      coupon_id: coupon,
    };
    try{
      const res = await axios.post('admin/v1/clinic/user-coupons', userCoupon);
      setButtonText(t('Saved'));
      setTimeout(() => {
        setButtonText('Save');
      }, 1000);
    }catch(err){
      console.log(err);
    }
  }

  const getSelectLabel = (value) => {
     return couponCodeOptions.filter((item) => value == item.value)[0].code;
  }

  return (
    <Box className={classes.reviewBoxWrapper}>
      <Box className={classes.wrapper}>
        <Box style={{ width:'11.813rem', height:'11rem',backgroundColor:'#fff', borderRadius:'3px'}}>
        {avatar? <Box component="img" sx={{ width:'11.813rem', height:'11rem'}} src={avatar} /> :
          <Avatar
            id="demo-positioned-button"
            aria-haspopup="true"
            className={classes.avatar}
          />}
        </Box>
        <Box className={classes.informationStyles}>
          <Box>
            {profileResponse?.data?.user &&
              profileResponse?.data?.user?.first_name && profileResponse?.data?.user?.last_name && (
                  <Typography className={`${classes.nameStyle} ${classes.fontSizeBG} ${classes.bold} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>
                    {profileResponse?.data?.user?.first_name} {profileResponse?.data?.user?.last_name}
                  </Typography>
              )}
            {profileResponse?.data?.user&& (
                      <Typography className={`${classes.bold} ${classes.typoColor} ${classes.fontSizeMD} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>
                        {profileResponse?.data?.user?.std_first_name_en} {profileResponse?.data?.user?.std_last_name_en}
                      </Typography>
                  )}
                  
            {profileResponse?.data?.user &&
                  profileResponse?.data?.user?.id && (
                      <Typography className={`${classes.bold} ${classes.typoColor} ${classes.fontSizeMD} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>
                        ID {profileResponse?.data?.user?.id}
                      </Typography>
                  )}
          </Box>
          {profileResponse?.data?.user?.phone && (
                  <Typography className={`${classes.thin} ${classes.typoColor} ${classes.fontSizeSM} ${i18n.language == 'ar' ? `${classes.fontFamilyAlmarai} ${classes.phoneNbAr}` : classes.fontFamilyEina}`}>
                      {profileResponse?.data?.user?.phone}
                    </Typography>
                  )}
          {profileResponse?.data?.user?.email && (
              <Typography className={`${classes.thin} ${classes.typoColor} ${classes.fontSizeSM} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>
                {profileResponse?.data?.user?.email}
              </Typography>
          )}
          <Box style={{display:'flex', justifyContent:'space-between'}}>
            <Box style={{backgroundColor:'#DFE3E8', width:'fit-content', padding:'0.5rem 1rem'}}>
              <Typography className={`${classes.typoColor} ${classes.fontSizeSM}`}> {t("Preferred-language")}   
                {profileResponse?.data?.options?.language
                    ? profileResponse?.data?.options?.language == "en"
                      ? <strong>{t("English")}</strong>
                      : profileResponse?.data?.options?.language == 'ar'
                    ? <strong>{t("Arabic")}</strong> : <strong>{t("English")}</strong> : <strong>{t("English")}</strong>
                }
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {couponCodeOptions.length > 0 && 
      <Box className={classes.couponWrapper}>
        <Tooltip
          arrow
          title={`${props?.membershipDisable ? t('membership-upload-condition'): ''}`}
          placement="top" 
          >
            <FormControl className={classes.selectReview}>
              <Select style={{padding: '0.2rem'}}
                disabled={clinicImpersonated() || props?.membershipDisable}
                labelId="demo-select-small"
                id="demo-select-small"
                value={coupon}
                onChange={(e) => {setCoupon(e.target.value)}}
                displayEmpty={true}
                renderValue={value => value ? getSelectLabel(value) : <span style={{color: 'gray'}}>{t('Membership-program')}</span>}
              >
                <MenuItem value='' style={{color: 'red'}}>None</MenuItem>
                {couponCodeOptions.map((coupon, key) => <MenuItem key={key} name={coupon.code} value={coupon.value}>{coupon.code}</MenuItem>)}
              </Select>
            </FormControl>
        </Tooltip>
        <Button disabled={clinicImpersonated() || props?.membershipDisable} style={{textDecoration:'none', backgroundColor:"#ff2c46", color: clinicImpersonated() || props?.membershipDisable ? '#ad3c3c' : '#fff', borderRadius:'3px'}} onClick={saveUserCoupon}>{buttonText}</Button>
      </Box>}
      {/* <Box className={classes.review}>
            <Button style={{textDecoration:'none', backgroundColor:"#ff2c46", color:'#fff', padding: '0.5rem 1rem', borderRadius:'3px'}} href='https://www.trustpilot.com/evaluate/basma.com' target='_blank'>{t('Add-a-customer-review')}</Button>
      </Box> */}
      {/* <Box className={classes.review}>
        <Tooltip
          arrow
          title={`${!(profileResponse?.calendlyResponse) ? 'Appointment must be completed first': ''}`}
          placement="top" 
          >
            <div>
              <Button disabled = {(!(profileResponse?.calendlyResponse) || clinicImpersonated())} style={profileResponse?.calendlyResponse == true && !clinicImpersonated() ? {textDecoration:'none', backgroundColor:"#ff2c46", color:'#fff', padding: '0.5rem 1rem', borderRadius:'3px'} : {textDecoration:'none', backgroundColor:"#808080", color:'#fff', padding: '0.5rem 1rem', borderRadius:'3px', marginRight: '5px'}} href={profileResponse?.calendlyLink} target='_blank'>
                  <span>{t('Book-a-tele-consultation')}</span>
              </Button>
            </div>
        </Tooltip>
      </Box> */}
      </Box>
  );
}
export default React.memo(Profile);
