import Cookies from "js-cookie";

export const typeOfFile = (value) => {
    const n = value.lastIndexOf('.');
    const validType = ['stl'];
    const value2 =  value.split('.', n);
    if(validType.indexOf(value2[value2.length - 1]) != -1){
      return true;
    }else{
      return false;
    }
}

export const FILE = 'file';
export const NOTFILE = 'not file';

export const imageValue = (value)=>{
  if(value){
    return !typeOfFile(value) ? value : "/assets/images/stl-thumbnail.png"
  }else{
    return null
  }
}

export const imageValueV2 = (value) => {
  if(value) {
    return "/assets/images/stl-thumbnail.png"
  } else {
    return null
  }
}

export const imageScansValue = (value)=>{
    if(value){
      return  process.env.REACT_APP_AWS_URL + value 
    }else{
      return null
    }
}

export const scrollToTop = (value) =>{
  window.scrollTo({
    top: value,
    behavior:'smooth'
  })
}

// Convert A to 1 and so on
export const convertToNumber = (char) => {
  return char.charCodeAt(0) - 'A'.charCodeAt(0) + 1;
}

export const scanType = (value) => {
  let scan;
  switch (value) {
    case "First Visit":
    case "Walk-in":
    case "Retainer Scan":
      scan = "Scans";
      break;
    case "Redo Scan - Scan Error":
      scan = "Redo-Scans-Scan-Error";
      break;
    case "Redo Scan - Post Dental Work":
      scan = "Redo-Scan-Post-Dental-Work";
      break;
    case "Redo Scan - Post Cleaning":
      scan = "Redo-Scan-Post-Cleaning";
      break;
    case "Redo Scan - Post Wire Removal":
      scan = "Redo-Scan-Post-Wire-Removal";
      break;
    case "Refinement Scan":
      scan = "Refinement-Scan";
      break;
    case "Refinement Scan - Scan Error":
      scan = "Refinement-Scan-Scan-Error";
      break;
    case "MCA - Scan":
      scan = "MCA-Scan";
      break;
    case "MCA Scan - Scan Error":
      scan = "MCA-Scan-Scan-Error";
      break;
    default:
      scan = "";
  }
  return scan;
};

export const appointmentTranslation = (value) => {
  let appointment;
  switch (value) {
    case "First Visit":
      appointment = "first-visit";
      break;
    case "Walk-in":
      appointment = "Walk-in";
      break;
    case "Post IPR Photos":
      appointment = "post-ipr-photos";
      break;
    case "Redo Scan Photos":
      appointment = "redo-scan-photos";
      break;
    case "X-RAY":
      appointment = "X-Ray";
      break;
    case "OPG":
      appointment = "OPG";
      break;
    case "Dental Checkup":
      appointment = "dental-checkup";
      break;
    case "Periodontal Checkup": 
      appointment = "periodontal-checkup";
      break;
    case "Redo Scan - Scan Error":
      appointment = "Redo-Scans-Scan-Error";
      break;
    case "Redo Scan - Post Dental Work":
      appointment = "Redo-Scan-Post-Dental-Work";
      break;
    case "Redo Scan - Post Cleaning":
      appointment = "Redo-Scan-Post-Cleaning";
      break;
    case "Redo Scan - Post Wire Removal":
      appointment = "Redo-Scan-Post-Wire-Removal";
      break;
    case "Refinement Scan":
      appointment = "Refinement-Scan";
      break;
    case "Refinement Scan - Scan Error":
      appointment = "Refinement-Scan-Scan-Error";
      break;
    case "MCA - Scan":
      appointment = "MCA-Scan";
      break;
    case "MCA Scan - Scan Error":
      appointment = "MCA-Scan-Scan-Error";
      break;
    case "Retainer Scan":
      appointment = "retainer-scan";
      break;
    case "Post Treatment Photos":
      appointment = "post-treatment-photos";
      break;
    case "Measure Spaces":
      appointment = "measure-spaces";
      break;
    case "Rebond Buttons":
      appointment = "rebond-buttons";
      break;
    case "Remove Excess Around Attachments":
      appointment = "remove-excess-around-attachments";
      break;
    case "Add Attachments":
      appointment = "add-attachments";
      break;
    case "Buttons":
      appointment = "buttons";
      break;
    case "IPR":
      appointment = "ipr";
      break;
    case "Rebond Attachments":
      appointment = "rebond-attachments";
      break;
    case "MCA Photos":
      appointment = "mca-photos";
      break;
    case "Refinement Photos":
      appointment = "refinement-photos";
      break;
    default:
      appointment = "";
  }
  return appointment;
}

export const convertNumberToArabic = (t,value) =>{
  if(value != null){
    let value2 = value.toString();
    let number = '';
    for (let i = 0; i < value2.length; i++) {
      number = number + t(value2[i]);
    }
    return number; 
  }
  return 0;
}

export const handleReadNotes = (array, opennote, key, value) => {
    const newData = array.map((res)=> {
        if(res.user_id == opennote){
            return {
                ...res,
                [key]: value
            }
        }
        return res;
    });
    return newData
}

export const clinicImpersonated = () =>{
  return Cookies.get('clinic_impersonate') == 1;
}

export const iconColors = {
  'First Visit': {'background':'#FF2C4C','color':"white"},
  'IPR': {'background':'#52b788','color':"white"},
  'Redo Scan': {'background':'#F2B620','color':"white"},
  'OPG': {'background':'#AECE46','color':"white"},
  'Cleaning': {'background':'#9163CB','color':"white"},
  'Walk-in': {'background':'#d0e0e3','color':"white"},
  'Redo Impression': {'background':'#D5BDAF','color':"#434343"},
  'Offline Booking': {'background':'#55BB8C','color':"white"},
  'Buttons': {'background':'#00acac','color':"white"},
  'Add Attachments': {'background':'#FF7F51','color':"white"},
  'Remove Attachments': {'background':'#FF9B54','color':"white"},
  'Removal Of Buttons': {'background':'#F968DF','color':"white"},
  'X-RAY': {'background':'#e5fc9a','color':"#434343"},
  'Dental Checkup': {'background':'#D2B7E5','color':"white"},
  'Remove Fixed Retainers': {'background':'#fff372','color':"#434343"},
  'Redo Scan Photos': {'background':'#c1d3fe','color':"#434343"},
  'Post IPR Photos': {'background':'#b7e4c7','color':"#434343"},
  'Refinement Scan': {'background':'#FB6F92','color':"white"},
  'Periodontal Checkup': {'background':'#dec9e9','color':"#434343"},
  'Rebond Attachments': {'background':'#FED8B1','color':"#434343"},  
  'Redo Scan - Scan Error': {'background':'#87ABFF','color':"white"},
  'Redo Scan - Post Dental Work': {'background':'#5091F8','color':"white"},
  'Redo Scan - Post Cleaning': {'background':'#4476C6','color':"white"},
  'Redo Scan - Post Wire Removal': {'background':'#0E5A98','color':"white"},
  'Refinement Scan - Scan Error': {'background':'#FFC2D1','color':"#434343"},
  'MCA Scan': {'background':'#00acac','color':"#434343"},
  'MCA Scan - Scan Error': {'background':'#00CCCC','color':"white"},
  'Retainer Scan': {'background':'#f1e34d','color':"#434343"},
  'Replace Powerchain': {'background':'#a5ffd6','color':"white"},
  'Post Treatment Photos': {'background':'#F17070','color':"white"},
  'Extraction': {'background':'#d05656','color':"white"},
  'Measure Spaces': {'background':'#4e8269','color':"white"},
  'Rebond Buttons': {'background':'#ff00d1','color':"white"},
  'Remove Excess Around Attachments': {'background': '#ff6d01', 'color': "white"},
  'MCA Photos': {'background': '#ff6d01', 'color': "white"},
  'Refinement Photos': {'background': '#ff6d01', 'color': "white"},
}