import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppointmentProcedureFiles from "./appointment-procedure-files.component";
import { useEffect, useState } from "react";
import { appointmentTranslation } from "../../util/functions";

const useStyles = makeStyles(() => {
  return {
    fontFamilyAlmarai: {
      fontFamily: "Almarai-semi !important",
    },
    fontFamilyEina: {
      fontFamily: "Eina-semi !important",
    },
    wrapper: {
      margin: "auto",
      marginBottom: "1rem",
      width: "60%",
      backgroundColor: "#FFFFFF",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
      ["@media only screen and (max-width: 1000px) "]: {
        width: "100%",
      },
    },
    headerWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "1.375rem 1.313rem",
      backgroundColor: "#FFFFFF",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    infoWrapper: {
      padding: "2rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    typoDesign: {
      height: "24px",
      width: "fit-content",
      color: "#212B36",
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "24px",
    },
    bold: {
      fontWeight: "bold",
    },
    btn: {
      height: "36px",
      width: "78px",
      border: "1px solid #C4CDD5",
      borderRadius: "3px",
      background: "linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%)",
      boxShadow: "0 1px 0 0 rgba(22,29,37,0.05)",
    },
    flex: {
      display: "flex",
    },
    loadingBox: {
      margin: "0 auto",
      textAlign: "center",
      padding: 30,
      color: "#FF2C46",
    },
    loading: {
      textAlign: "center",
      margin: "0 auto",
      color: "#ff2c46",
    },
  };
});
const AppointmentProcedures = ({
  procedure,
  handleTabsExpand,
  userId,
  appointmentId,
  openCloseTabs,
  setDisable,
  disable,
  index,
  scrollToExpandedTab,
}) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [isArabic, setIsArabic] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (i18n.language === "ar") {
      setIsArabic(true);
    }
    if (i18n.language === "en") {
      setIsArabic(false);
    }
  }, [i18n.language, isArabic]);

  if (procedure.required_files.length === 0) {
    return <></>;
  }

  let customTitle = isArabic
    ? `${t("data")} ${t(appointmentTranslation(procedure.procedure))}`
    : `${t(appointmentTranslation(procedure.procedure))} ${t("data")}`;
  
  if (procedure.procedure === "Walk-in") {
    customTitle = t("Patient-Data");
  }

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.headerWrapper}>
        <Typography
          className={`${classes.typoDesign} ${classes.bold} ${
            i18n.language === "ar"
              ? classes.fontFamilyAlmarai
              : classes.fontFamilyEina
          }`}
        >
          {customTitle}
        </Typography>
        <Button
          onClick={() => navigate(`/dashboard/users/${userId}`)}
          className={`${classes.btn} ${
            i18n.language === "ar"
              ? classes.fontFamilyAlmarai
              : classes.fontFamilyEina
          }`}
        >
          {t("Close")}
        </Button>
      </Box>
      <div className={classes.infoWrapper}>
        {procedure.required_files.map((caseRecord, idx) => (
          <AppointmentProcedureFiles
            key={idx}
            caseRecord={caseRecord}
            procedure={procedure}
            index={index}
            handleTabsExpand={handleTabsExpand}
            appointmentId={appointmentId}
            openCloseTabs={openCloseTabs}
            setDisable={setDisable}
            disable={disable}
            userId={userId}
            scrollToExpandedTab={scrollToExpandedTab}
          />
        ))}
      </div>
    </Box>
  );
};

export default AppointmentProcedures;
