import { Box, makeStyles, Typography,Checkbox ,FormControlLabel, Button, Menu, Grid, MenuItem, Radio, CircularProgress, RadioGroup, InputLabel } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import EnhancedTable from '../DataTable/EnhancedTable';
import axios from '../../util/axios'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import Note from '../Note/Note';
import Edit  from '../Note/EditNote' 
import { TabsControllerContext } from '../../Context/Tabs/TabsProvider';
import "../../App.css";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { clinicImpersonated, convertNumberToArabic, handleReadNotes } from '../../util/functions';
import { textAlign } from '@mui/system';
import ActiveContext from '../../Context/Context';

const useStyles=makeStyles((theme)=>{ return {
    fontFamilyAlmarai:{
        fontFamily:'Almarai-semi !important'
    },
    fontFamilyEina: {
        fontFamily: "Eina-semi !important",
    },
    boxWrapper: {
    },
    box:{
        display:'flex',
        alignItems:"center",
        justifyContent:"space-around",
        flexWrap:"wrap"
    },
    checkContainer:{
    flex:"1 1 auto",
    margin:0,
    minWidth:'fit-content',
    justifyContent:"center",
    },
    menuBox: {
        display: "flex"
    },
    filterButtonWithArrow: {
        border: '1px lightgray solid',
        borderRadius: 0,
        // borderRight: 'none'
    },
    loadingBox:{
        margin: "0 auto",
        textAlign: "center"
    },
    RadioGroup:{
        "& li":{
            paddingLeft:0
        }
    },
    loading: {
        textAlign: 'center',
        margin: "0 auto",
        color: '#ff2c46'
    },
    appointmentHide:{
        color: '#212B36',
        // fontFamily:"Eina-light!important",
        fontWeight:'500',
        fontSize: '12px!important'
    },
    searchBar: {
        //option 1
        //  "&:focus":{
        //     outline:" none !important",
        //     borderColor: "#ff2c46",
        //     boxShadow:" 0 0 10px #ff2c46",
        // },
        // end
        //option 2
        "&:focus":{
            outlineColor: "#ff2c46",
            outlineStyle: "solid",
        },
        // end
        width: '60%',
        zIndex:10,
        paddingLeft: 10,
        height:41,
        border: '1px lightgray solid',
        '&::placeholder': {
            fontWeight: 400,
          },
          "& label.Mui-focused": {
            color: "#ff2c46"
          },
    },
    filterWrapper: {
        flex:"1 1 auto",
        justifyContent:"center",
        display: "flex",
        padding: 20
    },
    addNote: {
        width:"25%",
        textAlign: 'center',
        marginTop: 10,
        backgroundColor:"#FF2C46",
        // fontFamily:"Eina, sans-serif!important",
        float:"right",
        boxSizing:" border-box",
        border: "1px solid #C4CDD5",
        borderRadius: "3px",
        boxShadow:" 0 1px 0 0 rgba(22,29,37,0.05)",
    
     },
     appointment:{
        flex:"1 1 auto",
        display:"inline-block",
        minWidth:'fit-content',
        color: '#9B9BA1',
        fontWeight: "Eina-semi!important",
        fontSize: '14px!important',
        textAlign:'center'
    },
    blackColor: {
        color : '#000'
    }
 } });

export default function Patients() {
    const classes=useStyles();
    const [data, setData] = useState([])
    const [page, setPage] = useState(1);
    const { t, i18n } = useTranslation();
    const [perPage, setPerPage] = useState(10);
    const [field, setField] = useState('id');
    const [sort, setSort] = useState('desc')
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(true)
    const [notificationLoading, setnotificationLoading] = useState(true)
    const[openEditNote,setOpenEditNote]=useState(0)
    const[notes,setNotes]=useState([])
    const[openAddNote,setOpenAddNote]=useState(0)
    const[newNote,setNewNote]=useState("")
    const[editNote,setEditNote]=useState({})

    const {
		searchPatients,
        patientfilter,
        checkHide,
        openNote,
        checkHideUnqualified,
        refreshComment,
        actions: { setOpenNote, setRefreshComment, setSearch,setSearchCalendar,setSearchCancelled,handlePatientSearch,handleKeyPressPatient,setAppointmentfilter,setPatientfilter,setCheckHide,setCalendarfilter,setCheckHideUnqualified },
	} = useContext(TabsControllerContext);
    const {
        filesImage,setFilesImage,imagesKey,setImageKey,images,setImages
      } = useContext(ActiveContext);
    
    useEffect(()=>{
        setSearch("");
        setSearchCalendar("");
        setSearchCancelled("");
        setLoading(true);
        const newField = field == 'user_id'?'id': field == 'user_sub_status'?'sub_status' : field;
        axios.get(`/admin/v1/clinic/patients?clinic_id=${Cookies.get('clinic_id')}&unqualified=${checkHideUnqualified || patientfilter == 'Unqualified' ? 1 : 0}&cancelled=${checkHide ? 1 : 0}&search=${searchPatients}&filter=${patientfilter}&per_page=${perPage}&page=${page}&field=${newField}&type=${sort}`)
        .then((response) => {
            const dat = response.data.data
            setData(dat)
            setTotal(response.data.total)
        }).catch((err)=> {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    },[checkHideUnqualified,checkHide,page,perPage,field,sort, patientfilter,searchPatients])
    
    const headCells = [
        {
            id: 'user_id',
            label: t('ID'),
        },
        {
            id: 'first_name',
            label: t('First-Name'),
            type:"appointment_name",
        },
        {
            id: 'last_name',
            label: t('Last-Name'),
            type:"appointment_name"
        },
        {
            id: 'phone_number',
            label: t('Phone-Number'),
            type: 'phone',
            // hideRow: true
        },
        {
            id: 'booking_date',
            label: t('Confirmed-Date'),
        },
        {
            id: 'booking_time',
            label: t('Confirmed-time'),
        },
        {
            id: 'user_photos',
            label: t('Photos'),
            type: 'photos',
            hideRow: false,
            hideSort: true
        },
        {
            id: 'user_questions',
            label: t('Questions'),
            type:'questions',
            hideSort: true
        },
        {
            id: 'user_scans',
            label: t('Scans'),
            type:'scans',
            hideSort: true
        },
        {
            id: 'user_sub_status',
            label: t('Status'),
            hideSort: true
        },
        {
            id: 'user_type',
            label: t('Type'),
            type: 'type',
            hideSort: true
        },
        // {
        //     id: 'menu',
        //     label: 'Action',
        //     type: 'menu',
        //     hideSort: true,
        // },
    ]
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };


    useEffect(async()=>{
        if(!openNote){
            setNotes([])
            setOpenEditNote(0)
            setEditNote({})
            return;//not chosen a note
        }
        if(!openEditNote){
            //not chosen a comment to edit but chosen a user to view the note
            try{
            setnotificationLoading(true)
            const res= await axios.get(`/admin/v1/users/${openNote}/comments`)
            if(res.data.success){
                setNotes(res.data.data.data)
                const newData = handleReadNotes(data, openNote, 'unread_comments', false)
                setData(newData)
            }
            }catch(err){
                console.log(err)
            }finally{
            setnotificationLoading(false)
            }
            
        }
        else{
            //chosen a comment to edit display it
            try{
                setnotificationLoading(true)
                const res= await axios.get(`/admin/v1/users/${openNote}/comments/${openEditNote}`)
                if(res.data.success){
                    setEditNote(res.data.data.comment)
                }
                }catch(err){
                    console.log(err)
                }finally{
                setnotificationLoading(false)
                }
        }
    

    },[openNote,openEditNote,openAddNote])
    const handleCloseNote=()=>{
        if(openEditNote){
            setnotificationLoading(true)
            setOpenEditNote(0)
            setEditNote({})
        }
        else{
            setNotes([])
            setNewNote("")
            setOpenAddNote(0)
        }
        setFilesImage([])
        setImageKey([])
        setImages([])
    }
    const handleConfirmNote=async()=>{
        if(openEditNote){
            //update before closing edit modal
            let body={comment:editNote,images:imagesKey}
            try{
                setnotificationLoading(true)
                const res= await axios.post(`/admin/v1/users/${openNote}/comments/${openEditNote}`,body)
                if(res.data.success){
                console.log('updated')
                }
                }catch(err){
                    console.log(err)
                }finally{
                setnotificationLoading(false)
                }
            setnotificationLoading(true)
            setOpenEditNote(0)
            setEditNote({})
        }
        else{
            if(newNote){
                let body={comment:newNote,images:imagesKey}
                try{
                    setnotificationLoading(true)
                    const res= await axios.post(`/admin/v1/users/${openNote}/comments`,body)
                    if(res.data.success){
                    console.log('added')
                    }
                    }catch(err){
                        console.log(err)
                    }finally{
                    setOpenAddNote(0)
                    setNewNote("")
                    }
                }
        }
        setFilesImage([])
        setImageKey([])
        setImages([])
    }
    const handleOutsideClick=()=>{
        setOpenNote(0)
        setOpenAddNote(0)
        setOpenEditNote(0)
        setNewNote("")
        setEditNote({})
        setFilesImage([])
        setImageKey([])
        setImages([])
    }
    const menu = [
        { id: 'Confirm', action: () => console.log('edit') },
        { id: 'No Answer', action: () => console.log('delete') },
        { id: 'Reschedule', action: () => console.log('delete') },
        { id: 'Delete', action: () => console.log('delete') },
        { id: 'Cancel', action: () => console.log('delete') },
    ]
    const handleRadioButtonClick = (e)=> { 
        if(e.target.tagName=="INPUT"){
            if(e.target.value=="Confirmed"||e.target.value=="Completed"||e.target.value=="No Show"){
                setAppointmentfilter("");
                setCalendarfilter(e.target.value);
            }else if(e.target.value=="Pending"||e.target.value=="No Answer"||e.target.value=="Cancelled" || e.target.value=="Postponed"){
                setCalendarfilter("");
                setAppointmentfilter(e.target.value);
            }else{
                setCalendarfilter("");
                setAppointmentfilter("");
            }
            e.target.value==""?setPatientfilter(""):setPatientfilter(e.target.value);
            setAnchorEl(null);
        }   
    }
    return (
        (loading || !page )? <Box className={classes.loadingBox}><CircularProgress className={classes.loading}/></Box> : <Box className={classes.boxWrapper}>
            <Box className={classes.box}>
            <Grid className={classes.filterWrapper} item lg={12} xs={12}>
                <Button
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    className={classes.filterButtonWithArrow}
                >
                    {!patientfilter ? t("Filter") : patientfilter.includes(' ') ? t(patientfilter.replace(' ', '-')) :  t(patientfilter)} <ArrowDropDownIcon/>
                </Button>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        // onClick={}
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                        }}
                    > 
                        <RadioGroup name="radio-buttons-group" className={classes.RadioGroup}>
                       <InputLabel className={classes.blackColor}><MenuItem ><Radio onClick={handleRadioButtonClick} value={''} checked={patientfilter == ''? true :false}/>{t('All')}</MenuItem></InputLabel>
                       <InputLabel className={classes.blackColor}><MenuItem ><Radio onClick={handleRadioButtonClick} value={'Pending'}  checked={patientfilter == 'Pending'? true :false}/>{t('Pending')}</MenuItem></InputLabel>
                       <InputLabel className={classes.blackColor}><MenuItem ><Radio onClick={handleRadioButtonClick} value={'Completed'}  checked={patientfilter == 'Completed'? true :false}/>{t('Completed')}</MenuItem></InputLabel>
                       <InputLabel className={classes.blackColor}><MenuItem ><Radio onClick={handleRadioButtonClick} value={'Confirmed'}  checked={patientfilter == 'Confirmed'? true :false}/>{t('Confirmed')}</MenuItem></InputLabel>
                       <InputLabel className={classes.blackColor}><MenuItem ><Radio onClick={handleRadioButtonClick} value={'Cancelled'}   checked={patientfilter == 'Cancelled'? true :false}/>{t('Cancelled')}</MenuItem></InputLabel>
                       <InputLabel className={classes.blackColor}><MenuItem ><Radio onClick={handleRadioButtonClick} value={'No Answer'}  checked={patientfilter == 'No Answer'? true :false}/>{t('No-Answer')}</MenuItem></InputLabel>
                       <InputLabel className={classes.blackColor}><MenuItem ><Radio onClick={handleRadioButtonClick} value={'No Show'}   checked={patientfilter == 'No Show'? true :false}/>{t('No-Show')}</MenuItem></InputLabel>
                       <InputLabel className={classes.blackColor}><MenuItem ><Radio onClick={handleRadioButtonClick} value={'Postponed'}   checked={patientfilter == 'Postponed'? true :false}/>{t('Postponed')}</MenuItem></InputLabel>
                       <InputLabel className={classes.blackColor}><MenuItem ><Radio onClick={handleRadioButtonClick} value={'Unqualified'}   checked={patientfilter == 'Unqualified'? true :false}/>{t('Unqualified')}</MenuItem></InputLabel>
                       </RadioGroup>
                </Menu>
                        <input 
                            type='search'
                            id="searchstyle"
                            className={classes.searchBar}
                            placeholder={t('Search')}
                            defaultValue={searchPatients}
                            onKeyPress={(e) => handleKeyPressPatient(e)}
                            onChange={(e) => handlePatientSearch(e)}
                        />
                </Grid>
            <Typography variant='body1' className={classes.appointment} >
                {convertNumberToArabic( t, total)} {t('Patient')}{total === 0 || total > 1 ? i18n.language == 'ar' ? '' : 's' : ''}
            </Typography>
            <FormControlLabel className={classes.checkContainer} control={<Checkbox checked={checkHide} onClick={()=>{
                setCheckHide((prev)=>{
                    return !prev;
                })
            }}/>}  label={<Typography variant='body1' className={`${classes.appointmentHide} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`} >
            {t('Hide-cancelled-patients')}
            </Typography>} />
            <FormControlLabel className={classes.checkContainer} control={<Checkbox checked={checkHideUnqualified} onClick={()=>{
                setCheckHideUnqualified((prev)=>{
                    return !prev;
                })
            }}/>}  label={<Typography variant='body1' className={`${classes.appointmentHide} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`} >
            {t('Show-Unqualified-patients')}
            </Typography>} />
            </Box>
           <Box>
           {data && <EnhancedTable
                rowsData={data}
                headCells={headCells}
                menu={menu}
                setPage={setPage}
                perPage={perPage}
                page={page}
                field={field}
                setField={setField}
                sort={sort}
                setSort={setSort}
                total={total}
                patient={true}
                setPerPage={setPerPage}
                setOpenNote={setOpenNote}
                ></EnhancedTable>}
           </Box>
           <ConfirmationDialog  open={openNote>0}
                 handleOutsideClick={()=>{handleOutsideClick()}}
                 handleClose={()=>{handleCloseNote()}}
                 handleConfirm={()=>{handleConfirmNote()}}
                 notes={true}
                 confirmationButtonText={openEditNote||openAddNote ? t("Save")  : ""}
                 cancelButtonText=  {openEditNote||openAddNote ? t("Cancel")  : ""}  
                 dialogTitle= {openEditNote ? t("Edit-Note")  :openAddNote? t("Add-Note") : t("Notes")}  
                 dialogContentText=""
                 loading={notificationLoading}
                  > {openEditNote  ? 
                  <Edit data={editNote} setData={setEditNote} />
                  : openAddNote ? <Edit data={newNote} setData={setNewNote} />:<><Note  setOpenEditNote={setOpenEditNote} data={notes} setnotificationLoading={setnotificationLoading}/>
                   <Button disabled={clinicImpersonated()} className={`${classes.addNote} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`} color="secondary" variant='contained' onClick={()=>{setOpenAddNote(1)}}>{t('Add-Note')}</Button></>}</ConfirmationDialog>
        </Box>
    )
}