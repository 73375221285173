import {
  Box,
  Button,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { clinicImpersonated } from "../../util/functions";
import ProcedureHeader from "./procedure-header.component";
import ScreeningPhotos from "./ScreeningPhotos";

const useStyles = makeStyles(() => {
  return {
    photoWrapper: {
      padding: "1rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    photoTitleWrapper: {
      marginTop: '4rem',
    },
    photoCheckWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    checkBoxWrapper: {
      transform: "scale(0.7)",
      marginTop: "8px",
    },
    photoBox: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    photoWidthSM: {
      width: "60%",
      ["@media only screen and (max-width: 700px) "]: {
        width: "100%",
      },
    },
    photoWidth: {
      width: "70%",
      ["@media only screen and (max-width: 700px) "]: {
        width: "100%",
      },
    },
    singlePhotoBox: {
      display: "flex",
      width: "70.19px",
      height: "109.03px",
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: "1rem",
      ["@media only screen and (max-width: 500px) "]: {
        flex: "33%",
      },
    },
    errorMessage: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      ["@media only screen and (max-width: 500px) "]: {
        flex: "33%",
      },
    },
    title: {
      height: "16px",
      width: "134px",
      color: "#303030",
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "22px",
      marginBottom: "1rem",
    },
    errors: {
      color: "#D72C0D",
      fontSize: "12px !important",
      marginTop: "1rem",
    },
    error: {
      color: "#D72C0D",
      fontSize: "12px !important",
    },
    imageTitle: {
      height: "33.6px",
      width: "73px",
      color: "#212B36",
      fontSize: "9px !important",
      letterSpacing: 0,
      lineHeight: "16.8px",
      textAlign: "center",
    },
    saveBtn: {
      display: "block",
      width: "65px",
      marginTop: "0.5rem",
      marginLeft: "auto",
      borderRadius: "4.7px",
      color: "#fff",
      backgroundColor: "#FF2C46",
      "&:hover": {
        backgroundColor: "#FF2C46",
      },
    },
    loadingBox: {
      margin: "0 auto",
      textAlign: "center",
      padding: 30,
      color: "#FF2C46",
    },
    loading: {
      textAlign: "center",
      margin: "0 auto",
      color: "#ff2c46",
    },
  };
});

export default function AppointmentPhotos({
  disable,
  appointmentId,
  appointmentType,
  handleTabsExpand,
  index,
  data = { isAllProvided: null, data: {} },
  openCloseTabs,
  scrollToExpandedTab,
}) {
  const { data: photosData, isAllProvided } = data;
  const classes = useStyles();
  const [imagePreview, setImagePreview] = useState(photosData);
  const [success, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isAllDataProvided, setIsAllDataProvided] = useState(isAllProvided);
  const { t } = useTranslation();

  const handleDatafetched = (value) => {
    setImagePreview({...value});
  };

  useEffect(() => {
    scrollToExpandedTab(index, "photos");
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type="photos"
      headerTitle="Photos"
      index={index}
      iconCheck={isAllDataProvided}
    >
      {openCloseTabs[index]?.photos && (
        <Box className={`${classes.photoWrapper} photos-${index}`}>
          <Typography className={classes.title}>
            {t("Screening Photos")}
          </Typography>
          <ScreeningPhotos
            imagePreview={imagePreview}
            appointmentType={appointmentType}
            disable={disable}
            setSuccess={setSuccess}
            appointmentId={appointmentId}
            handleDatafetched={handleDatafetched}
            classes={classes}
            setErrorMsg={setErrorMsg}
            t={t}
            setIsAllDataProvided={setIsAllDataProvided}
          />
          <Box className={classes.errorMessage}>
            <Button
              disabled={clinicImpersonated()}
              className={classes.saveBtn}
              onClick={() => {}}
            >
              {success ? t("Saved") : t("Save")}
            </Button>
            <Typography className={classes.errors}>{errorMsg}</Typography>
          </Box>
        </Box>
      )}
    </ProcedureHeader>
  );
}
