import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import moment from "moment";
import SelectDatePicker from "@netojose/react-select-datepicker";
import axios from "../../util/axios";
import { useTranslation } from "react-i18next";
import { clinicImpersonated } from "../../util/functions";
import { Checkbox } from "@shopify/polaris";
import ProcedureHeader from "./procedure-header.component";
import TreatmentApproach from "./TreatmentApproach";
import AdditionalInstructions from "./AdditionalInstructions";
import InformationRadioOptions from "../InformationRadioOptions";
import InformationCheckboxGroup from "./InformationCheckboxGroup";
import spacing from '../../assets/images/fv-info-details/spacing.svg';
import crossbite from '../../assets/images/fv-info-details/crossbite.svg';
import deepBite from '../../assets/images/fv-info-details/deep-bite.svg';
import crowding from '../../assets/images/fv-info-details/crowding.svg';
import openBite from '../../assets/images/fv-info-details/open-bite.svg';
import parse from "react-html-parser";

const useStyles = makeStyles(() => {
  return {
    card: {
      padding: '2rem',
      boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
      marginBottom: '1rem',
    },
    h3: {
      fontSize: '18px',
      color: '#303030',
      marginBottom: '1.5rem',
    },
    photoWrapper: {
      padding: "2rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    photoBox: {
      display: "flex",
      justifyContent: "space-between",
    },
    photoWidthSM: {
      width: "40%",
    },
    photoWidth: {
      width: "70%",
    },
    singlePhotoBox: {
      display: "flex",
      width: "70.19px",
      height: "109.03px",
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: "1rem",
    },
    title: {
      height: "16px",
      width: "134px",
      color: "#303030",
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "22px",
      marginBottom: "1rem",
    },
    imageTitle: {
      height: "33.6px",
      width: "73px",
      color: "#212B36",
      fontSize: "0.8rem !important",
      letterSpacing: 0,
      lineHeight: "16.8px",
      textAlign: "center",
    },
    saveBtn: {
      display: "block",
      width: "65px",
      marginTop: "0.5rem",
      marginLeft: "auto",
      borderRadius: "4.7px",
      color: "#fff",
      backgroundColor: "#FF2C46",
      "&:hover": {
        backgroundColor: "#FF2C46",
      },
    },
    procWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "1rem",
    },
    noBorder: {
      border: "none",
    },
    add: {
      backgroundColor: "#8EE2ED",
      color: "#fff",
    },
    errorMsg: {
      marginTop: "1rem",
      color: "red",
    },
    generalErrorMsg1: {
      marginTop: "1rem",
      color: "red",
    },
    generalErrorMsg: {
      marginTop: "1rem",
      color: "red",
      textAlign: 'end',
    },
    loadingBox: {
      margin: "0 auto",
      textAlign: "center",
      padding: 30,
      color: "#FF2C46",
    },
    loading: {
      textAlign: "center",
      margin: "0 auto",
      color: "#ff2c46",
    },
    textarea: {
      width: "100%",
      border: "none",
      resize: "none",
      backgroundColor: "rgba(211,215,219,0.25)",
      color: "#939393",
      padding: "0.8rem",
      "&:focus": {
        outline: "none",
      },
    },
    btnStyle: {
      backgroundColor: "rgba(211,215,219,0.25)",
      fontSize: "14px !important",
      width: "6.5rem",
      padding: "0.5rem 2.5rem",
      color: "#939393",
      cursor: "pointer",
    },
    paddingMd: {
      padding: "0.5rem 2rem",
    },
    paddingSM: {
      padding: "0.5rem 1.7rem",
    },
    paddingBG: {
      padding: "0.5rem 2.3rem",
    },
    archesBtn: {
      backgroundColor: "rgba(211,215,219,0.25)",
      fontSize: "14px !important",
      width: "6.5rem",
      color: "#939393",
      cursor: "pointer",
    },
    flexBox: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "0.5rem",
    },
    findingsTitlesBox: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '1rem',
    },
    findingsTitle2: {
      width: '50%',
    },
    desc: {
      color: "#4e4e4e",
      fontSize: "13px !important",
      fontWeight: 100,
    },
    findingsBox: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '1rem',
    },
    findingBox: {
      display: 'flex',
      columnGap: '1rem',
      justifyContent: 'space-between',
    },
    checkWrapper: {
      width: '45%',
    },
    textField: {
      width: '50%',
      backgroundColor: "rgba(211,215,219,0.25)",
      color: "#939393",
      padding: "0 0.2rem",
      outline: "none",
    },
    fullWidth: {
      width: "100%",
    },
    mediumWidth: {
      width: "14rem",
    },
    seMiWidth: {
      width: "21rem",
    },
    viewWidth: {
      display: "flex",
      marginTop: "0.5rem",
    },
    dateSelectoreWidth: {
      width: "30rem",
    },
    selected: {
      backgroundColor: "#8ee2ed",
      color: "#fff",
    },
    checkBoxWrapper: {
      display: "flex",
      flexDirection: "row",
      margin: "4px 0",
    },
    viewOnlyDate: {
      backgroundColor: "rgba(211,215,219,0.25)",
      fontSize: "14px !important",
      padding: "0.5rem 1.5rem 0.5rem",
      textAlign: " center",
      color: "#939393",
    },
    secTitle: {
      color: '#363636',
      fontWeight: '600',
    },
    colTitles: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    colTitle: {
      width: '45%',
      color: "#4e4e4e",
      fontSize: "13px !important",
    },
    redColTitle: {
      width: '50%',
      color: "red",
      fontSize: "13px !important",
      textDecoration: 'underline',
    },
  };
});
export default function ScreeningInformation({
  userId,
  appointmentId,
  appointmentType,
  data: infoData = { data: {}, isAllProvided: null },
  handleTabsExpand,
  index,
  openCloseTabs,
  setDisable,
  scrollToExpandedTab,
}) {
  const { data: informationData, isAllProvided, mandatory, show_new_info_ui } = infoData;
  const { t } = useTranslation();
  const [information, setInformation] = useState(informationData);
  const [iconInf, setIconInf] = useState(isAllProvided);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [cannotProceed, setCannotProceed] = useState(null);
  const dateOfBirth = information?.date_of_birth
  ? moment(information?.date_of_birth)
  : null;
  const [errorMsg, setErrorMsg] = useState({
    problem_question: "",
    dental_history: "",
    list_of_procedures: "",
    general: "",
  });
  const [infoDetails, setInfoDetails] = useState({
    gender: '',
    dentition_type: 'permanent',
    treatment_approach: 'full-arch',
    dental_conditions: {
      spacing: false,
      crossbite: false,
      deepBite: false,
      crowding: false,
      openBite: false,
    },
  });
  const [infoCheckboxes, setInfoCheckboxes] = useState([]);
  const [teethIds, setTeethIds] = useState({
    upper: [],
    lower: [],
    no_anterior_attachments: false,
    no_attachments: false,
  });
  const gender = [
    {
      value: 'male',
      label: 'male',
    },
    {
      value: 'female',
      label: 'female',
    },
  ];
  const clinicalFindings = [
    {
      label: 'dental-caries',
      value: 'dental_caries',
    },
    {
      label: 'dental-restorations',
      value: 'dental_restorations',
    },
    {
      label: 'calculus',
      value: 'calculus',
    },
    {
      label: 'impacted-teeth',
      value: 'impacted_teeth',
    },
    {
      label: 'missing-teeth',
      value: 'missing_teeth',
    },
    {
      label: 'retained-deciduous-teeth',
      value: 'retained_deciduous_teeth',
    },
    {
      label: 'periodontally-compromised-tooth',
      value: 'periodontally_compromised_tooth',
    },
    {
      label: 'gum-recession',
      value: 'gum_recession',
    },
    {
      label: 'abscess',
      value: 'abscess',
    },
  ];

  const emptySelectedTeeth = (prop) => {
    if(!teethIds[prop]) {
      const teeth = document.querySelectorAll('.additional-instructions-teeth-svg path');
      teeth.forEach(tooth => {
        tooth.style.fill = 'white';
      });
    }
  }

  const handleToothClick = (e, prop) => {
    if (prop === 'no_anterior_attachments') {
      emptySelectedTeeth(prop);
      setTeethIds((prev) => ({
        ...prev,
        [prop]: !prev[prop],
        no_attachments: false,
        lower: [],
        upper: [],
      }));
      return;
    }
    if (prop === 'no_attachments') {
      emptySelectedTeeth(prop);
      setTeethIds((prev) => ({
        ...prev,
        [prop]: !prev[prop],
        no_anterior_attachments: false,
        lower: [],
        upper: [],
      }));
      return;
    }
    if (e.target.tagName !== 'path') {
      return;
    }

    if (teethIds.no_attachments || teethIds.no_anterior_attachments) {
      setTeethIds((prev) => ({
        ...prev,
        no_attachments: false,
        no_anterior_attachments: false,
      }));
    }

    setTeethIds((prev) => {
      const newPropArray = prev[prop].includes(e.target.id)
        ? prev[prop].filter(id => id !== e.target.id)
        : [...prev[prop], e.target.id];

      e.target.style.fill = newPropArray.includes(e.target.id) ? '#fea3a3' : 'white';

      return {
        ...prev,
        [prop]: newPropArray
      };
    });
  };

  const onTagClick = (prop, id) => {
    setTeethIds((prev) => ({
      ...prev,
      [prop]: prev[prop].filter(item => item !== id)
    }));
    document.getElementById(id).style.fill = 'white';
  };

  const handleRadioButtonClick = (group, value) => {
    if (group === 'arches_to_treat') {
      setData((prev) => ({
        ...prev,
        arches_to_treat: value,
      }));
      return;
    }
    if (group === 'dental_conditions') {
      setInfoDetails(prev => ({
        ...prev,
        dental_conditions: {
          ...prev.dental_conditions,
          [value]: !prev.dental_conditions[value]
        }
      }));
      return;
    }
    let newRadioGroupTypes = {
      ...infoDetails,
      [group]: value,
    };
    if (group !== 'gender') {
      newRadioGroupTypes = {
        ...newRadioGroupTypes,
        dental_conditions: {
          spacing: false,
          crossbite: false,
          deepBite: false,
          crowding: false,
          openBite: false,
        },
      }
    }
    if (group === 'dentition_type') {
      newRadioGroupTypes = {
        ...newRadioGroupTypes,
        treatment_approach: 'full-arch',
      }
    }

    setInfoDetails(newRadioGroupTypes);
  }

  const handleIconInformationView = (value) => {
    setIconInf(value);
  };

  const handleInformationValue = (value) => {
    setInformation(value);
  };

  const handleCannotProceedChange = (e) => {
    setCannotProceed(e.target.value == 1);
    if(e.target.value == 0) {
      let clinicalFindings = data?.clinical_findings_v2 || [];
      clinicalFindings.filter(f => f.procedures).forEach(finding => {
        delete finding.procedures;
      });
      setData((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          clinical_findings_v2: clinicalFindings,
        }
      }));
    }
  }

  const handleChange = (value, type) => {
    if (!clinicImpersonated()) {
      if (type === "problem_question" || type === "dental_history") {
        setErrorMsg((prev) => ({
          ...prev,
          [type]: "",
        }));
      }
      setSuccess(false);
      setData((prev) => ({
        ...prev,
        [type]:
          type === "date_of_birth" ? moment(value).format("YYYY-MM-DD") : value,
      }));
    }
  };

  const handleChangeV2 = (value, condition, type, subType) => {
    if (!clinicImpersonated()) {
      if(type === 'clinical_findings_v2' ) {
        if(subType === 'condition' && value) {
          setErrorMsg((prev) => ({
            ...prev,
            dental_history: '',
          }));
        }
        let clinicalFindings = data?.clinical_findings_v2 || [];
        if(subType === 'condition') {
          if(value) {
            clinicalFindings.push({ condition });
          } else {
            clinicalFindings = clinicalFindings.filter(finding => finding.condition !== condition);
          }
          setData((prev) => ({
            ...prev,
            clinical_findings_v2: clinicalFindings,
          }));
          return;
        }

        let index = clinicalFindings.findIndex(finding => finding?.condition === condition);
        clinicalFindings[index] = {
          ...clinicalFindings[index],
          [subType]: value,
        };
        setData((prev) => ({
          ...prev,
          clinical_findings_v2: clinicalFindings,
        }));
      }
    }
  };

  const handleChangeProc = (value, i, type) => {
    if (!clinicImpersonated()) {
      setErrorMsg((prev) => ({
        ...prev,
        [type]: "",
      }));
      setData((prev) => {
        prev[type][i] = value;
        return { ...prev };
      });
    }
  };

  const addRow = () => {
    setData((prev) => ({
      ...prev,
      list_of_procedures: [...prev.list_of_procedures, ""],
    }));
  };

  const handleSave = () => {
    let errMsg = {
      problem_question: "",
      dental_history: "",
      list_of_procedures: "",
      general: '',
    };
    setErrorMsg(errMsg);
    if(data?.clinical_findings_v2?.length > 0 && !data.clinical_findings_v2.find(f => f.condition === 'none')) {
      if(cannotProceed === null) {
        errMsg = {
          ...errMsg,
          general: t('procedures-to-be-done-before-treatment-error-1'),
        };
        setErrorMsg(errMsg);
        scrollToSection('.pre-treatment-procedures');
        return;
      }
      if(cannotProceed && data.clinical_findings_v2.every(f => !f.procedures)) {
        errMsg = {
          ...errMsg,
          general: t('procedures-to-be-done-before-treatment-error-2'),
        };
        setErrorMsg(errMsg);
        scrollToSection('.pre-treatment-procedures');
        return;
      }
    }
    let listOfProcedures = [];
    if (data?.list_of_procedures) {
      listOfProcedures = data.list_of_procedures.filter((proc) => {
        if (proc) {
          return proc;
        }
      });
    }
    if ((mandatory && (!data?.problem_question || (!data?.dental_history && !data?.clinical_findings_v2?.length))) || ((data?.dental_history_na === 1 || data?.dental_history_na === true) && listOfProcedures.length === 0)) {
      if (mandatory) {
        if(!data?.problem_question) {
          errMsg = {
            ...errMsg,
            problem_question: t("radio-required"),
          };
        } else {
          errMsg = {
            ...errMsg,
            dental_history: t("radio-required"),
          };
        }
      }
      if ((data?.dental_history_na === 1 || data?.dental_history_na === true) && listOfProcedures.length === 0) {
        errMsg = {
          ...errMsg,
          list_of_procedures: t("error-add-procedure"),
        };
      }
      setErrorMsg(errMsg);
    } else {
      setLoading(true);
      let details = null;
      if(show_new_info_ui) {
        details = {};
        let info = infoDetails;
        if (Object.values(info.dental_conditions).every(x => x === false)) {
          details = {
            dentition_type: info.dentition_type,
            treatment_approach: info.treatment_approach,
          }
          if (info.gender) {
            details = {
              ...details,
              gender: info.gender,
            }
          }
        } else {
          if(!info.gender) {
            delete info.gender;
          }
          details = {
            ...info,
            dental_conditions: Object.keys(info.dental_conditions).filter(key => info.dental_conditions[key]),
          }
        }
        if (teethIds.no_anterior_attachments) {
          details = {
            ...details,
            avoid_attachments: {
              no_anterior_attachments: teethIds.no_anterior_attachments,
            }
          }
        } else {
          if (teethIds.no_attachments) {
            details = {
              ...details,
              avoid_attachments: {
                no_attachments: teethIds.no_attachments,
              }
            }
          } else {
            if (teethIds.upper.length > 0) {
              details = {
                ...details,
                avoid_attachments: {
                  ...details.avoid_attachments,
                  upper: teethIds.upper,
                }
              }
            }
            if (teethIds.lower.length > 0) {
              details = {
                ...details,
                avoid_attachments: {
                  ...details.avoid_attachments,
                  lower: teethIds.lower,
                }
              }
            }
          }
        }
      }
      let body = {
        ...data,
        list_of_procedures: listOfProcedures,
        procedure: appointmentType?.procedure,
        user_scan_info_id: appointmentId,
      };
      if(show_new_info_ui) {
        body = {
          ...body,
          details,
        };
      }
      if (body) {
        axios
          .post(`admin/v2/users/${userId}/case-record-information`, body)
          .then((res) => {
            if (res.data.success) {
              handleIconInformationView(res.data.isAllProvided);
              handleDataFetched(res.data.data);
              handleInformationValue(res.data.data);
              setSuccess(true);
            }
          })
          .catch((err) => {
            setSuccess(false);
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  };

  const handleDataFetched = (value) => {
    let findingsV2 = value.clinical_findings_v2 ? value.clinical_findings_v2 : [];
    if(findingsV2.length) {
      setCannotProceed(findingsV2.find(f => f.procedures) ? true : false);
    }
    setData({
      date_of_birth: value?.date_of_birth
        ? new Date(value?.date_of_birth)
        : null,
      problem_question: value?.problem_question,
      visited_dentist: value?.visited_dentist,
      teeth_healthy: value?.teeth_healthy,
      dental_history: value?.dental_history,
      dental_history_na: value?.dental_history_na === 1,
      list_of_procedures: value?.list_of_procedures && value.list_of_procedures.length > 0 ? value.list_of_procedures : [""],
      explain_issue: value?.explain_issue,
      arches_to_treat: value?.arches_to_treat ? value.arches_to_treat : 3,
      pain_symptoms: value?.pain_symptoms,
      clinical_findings_v2: value?.clinical_findings_v2,
    });
    if (value?.details) {
      let dentalConditions = {
        ...infoDetails.dental_conditions,
      };
      if (value.details?.dental_conditions) {
        value.details.dental_conditions.forEach(condition => {
          dentalConditions[condition] = true;
        });
      }
      let details = {
        gender: value.details?.gender ? value.details.gender : infoDetails.gender,
        dentition_type: value.details?.dentition_type ? value.details.dentition_type : infoDetails.dentition_type,
        treatment_approach: value.details?.treatment_approach ? value.details.treatment_approach : infoDetails.treatment_approach,
        dental_conditions: dentalConditions,
      };
      setInfoDetails(details);
      if (value.details.avoid_attachments) {
        if (value.details.avoid_attachments.no_anterior_attachments) {
          setTeethIds({
            no_anterior_attachments: value.details.avoid_attachments.no_anterior_attachments,
            no_attachments: false,
            upper: [],
            lower: [],
          });
        } else {
          if (value.details.avoid_attachments.no_attachments) {
            setTeethIds({
              upper: [],
              lower: [],
              no_attachments: value.details.avoid_attachments.no_attachments,
              no_anterior_attachments: false,
            });
          } else {
            // fill additional-instructions-teeth-svg with red color if selected
            let upper = [];
            let lower = [];
            if (value.details.avoid_attachments.upper) {
              upper = value.details.avoid_attachments.upper;
            }
            if (value.details.avoid_attachments.lower) {
              lower = value.details.avoid_attachments.lower;
            }
            setTeethIds({
              upper,
              lower,
            });
          }
        }
      }
    }
  };

  const scrollToSection = (section) => {
    let doc = document.querySelector(section);
    if (doc) {
      document.documentElement.style.scrollPaddingTop = "150px";
      doc.scrollIntoView({ behavior: "smooth" });
      document.documentElement.style.scrollPaddingTop = "0";
    }
  };

  useEffect(() => {
    handleDataFetched(information);
  }, []);

  useEffect(() => {
    if (clinicImpersonated()) {
      setDisable(true);
    } else {
      setDisable(data?.patient_data_locked);
    }
  }, []);

  useEffect(() => {
    scrollToExpandedTab(index, "screeningInformation");
  }, [openCloseTabs]);

  useEffect(() => {
    setInfoCheckboxes([
      {
        value: 'spacing',
        img: spacing,
        label: 'spacing',
        info: t('spacing-info'),
        disabled: false,
      },
      {
        value: 'crossbite',
        img: crossbite,
        label: 'crossbite',
        info: t('crossbite-info'),
        disabled: false,
      },
      {
        value: 'deepBite',
        img: deepBite,
        label: 'deep-bite',
        info: t('deep-bite-info'),
        disabled: infoDetails.dental_conditions.openBite,
      },
      {
        value: 'crowding',
        img: crowding,
        label: 'crowding',
        info: t('crowding-info'),
        disabled: false,
      },
      {
        value: 'openBite',
        img: openBite,
        label: 'open-bite',
        info: t('open-bite-info'),
        disabled: infoDetails.dental_conditions.deepBite,
      },
    ]);
  }, [infoDetails.dental_conditions])

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type="screeningInformation"
      headerTitle="Information"
      index={index}
      iconCheck={iconInf}
    >
      {openCloseTabs[index]?.screeningInformation && (
        <Box
          className={`${classes.photoWrapper} screeningInformation-${index}`}
        >
          {loading ? (
            <Box className={classes.loadingBox}>
              <CircularProgress className={classes.loading} />
            </Box>
          ) : (
            <>
              {
                show_new_info_ui ?
                <>
                  <Box className={classes.card}>
                    <h3 className={classes.h3}>{t('chief-complaint-diagnosis')}</h3>
                    <Box style={{ marginBottom: "1rem" }}>
                      <Typography className={classes.secTitle}>{t("Date-of-Birth")}</Typography>
                      {clinicImpersonated() ? (
                        <Box className={`${classes.viewWidth}`}>
                          <Typography className={classes.viewOnlyDate}>
                            {dateOfBirth ? dateOfBirth.format("MMMM") : "Month"}
                          </Typography>
                          <Typography className={classes.viewOnlyDate}>
                            {dateOfBirth ? dateOfBirth.format("DD") : "Date"}
                          </Typography>
                          <Typography className={classes.viewOnlyDate}>
                            {dateOfBirth ? dateOfBirth.format("YYYY") : "Year"}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          className={`${classes.dateSelectoreWidth} ${classes.flexBox}}`}
                        >
                          <SelectDatePicker
                            minDate={new Date(1940, 1, 1)}
                            className={"date-time-picker"}
                            value={
                              information?.date_of_birth
                                ? new Date(data?.date_of_birth)
                                : null
                            }
                            onDateChange={(e) => handleChange(e, "date_of_birth")}
                          />
                        </Box>
                      )}
                    </Box>
                    <br />

                    <Typography className={classes.secTitle}>{t('gender')}</Typography>
                    <RadioGroup name='gender' className='info-arches-container'>
                      {
                        gender.map((g, key) => {
                          return (
                            <InputLabel key={key} className='info-arches-InputLabel'>
                              <Radio onClick={() => handleRadioButtonClick('gender', g.value)} value={g.value} checked={infoDetails.gender === g.value} />
                              {t(g.label)}
                            </InputLabel>
                          )
                        })
                      }
                    </RadioGroup>
                    <br />

                    <Box>
                      <Typography className={classes.secTitle}>{parse(t("What-problem-are-you-looking-to-get-fixed?"))}</Typography>
                      <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
                        <TextareaAutosize
                          disabled={clinicImpersonated()}
                          placeholder={t("Add-comment...")}
                          className={classes.textarea}
                          minRows={6}
                          value={data?.problem_question ? data.problem_question : ''}
                          onChange={(e) =>
                            handleChange(e.target.value, "problem_question")
                          }
                        />
                      </Box>
                    </Box>
                    {errorMsg?.problem_question && (
                      <Typography className={classes.errorMsg}>
                        {errorMsg.problem_question}
                      </Typography>
                    )}
                    <br />

                    <Box style={{ marginBottom: "1rem" }}>
                      <Typography className={classes.secTitle}>{t("Any-other-dental-history-we-should-know-about?")}</Typography>
                      {
                        data?.dental_history ?
                        <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
                          <TextareaAutosize
                            disabled={clinicImpersonated()}
                            placeholder={t("Add-comment...")}
                            className={classes.textarea}
                            minRows={6}
                            value={data?.dental_history ? data.dental_history : ''}
                            onChange={(e) =>
                              handleChange(e.target.value, "dental_history")
                            }
                          />
                        </Box> :
                        <Box className={`${classes.fullWidth} ${classes.flexBox} ${classes.findingsBox}`}>
                          <Box className={classes.findingsTitlesBox}>
                            <Typography className='info-finding-col-1-title'>{t('mark-conditions')}</Typography>
                            <Typography className={`info-finding-col-1-title ${classes.findingsTitle2}`}>{t('tooth-number')}</Typography>
                          </Box>
                          {
                            clinicalFindings.map((finding, key) => {
                              let clinicalFinding = data?.clinical_findings_v2?.find(f => f?.condition === finding.value);
                              let checked = clinicalFinding ? true : false;
                              let toothNumber = clinicalFinding?.tooth_number ? clinicalFinding.tooth_number : '';
                              return (
                                <Box className={classes.findingBox} key={key}>
                                  <Box className={classes.checkWrapper}>
                                    <Checkbox
                                      label={t(finding.label)}
                                      checked={checked}
                                      onChange={(e) => handleChangeV2(e, finding.value, 'clinical_findings_v2', 'condition')}
                                    />
                                  </Box>
                                  <TextField
                                    disabled={clinicImpersonated() || !checked}
                                    className={`${classes.textField} info-v2-textfield`}
                                    value={toothNumber}
                                    onChange={(e) =>
                                      handleChangeV2(e.target.value, finding.value, 'clinical_findings_v2', 'tooth_number')
                                    }
                                  />
                                </Box>
                              )
                            })
                          }
                          <Box className={classes.findingBox}>
                            <Box className={classes.checkWrapper}>
                              <Checkbox
                                label={t('none')}
                                checked={data?.clinical_findings_v2?.find(f => f?.condition === 'none')}
                                onChange={(e) => handleChangeV2(e, 'none', 'clinical_findings_v2', 'condition')}
                              />
                            </Box>
                          </Box>
                        </Box>
                      }
                    </Box>
                    <Typography className={classes.errorMsg}>
                      {errorMsg.dental_history}
                    </Typography>
                    <InformationRadioOptions
                      title={t('dental-conditions-optional')}
                      subtitle={t('select-dental-conditions')}
                    >
                      <InformationCheckboxGroup
                        checkboxes={infoCheckboxes}
                        checkboxTypes={infoDetails.dental_conditions}
                        group='dental_conditions'
                        handleCheckboxClick={handleRadioButtonClick}
                        t={t}
                      />
                    </InformationRadioOptions>
                  </Box>
                  <Box className={classes.card}>
                    <h3 className={classes.h3}>{t('pre-orthodontic-treatment-required')}</h3>
                    <Typography className={classes.secTitle}>{t('pain-symtpoms')}</Typography>
                    <Typography className={classes.desc}>{t('pain-symtpoms-desc')}</Typography>
                    <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
                      <TextareaAutosize
                        disabled={clinicImpersonated()}
                        placeholder={t("Add-comment...")}
                        className={classes.textarea}
                        minRows={6}
                        value={data?.pain_symptoms ? data.pain_symptoms : ''}
                        onChange={(e) =>
                          handleChange(e.target.value, "pain_symptoms")
                        }
                      />
                    </Box>
                    <br />

                    {
                      data?.dental_history_na &&
                      <>
                        <Checkbox
                          label={t("pre-treatment-procedures-check")}
                          checked={data?.dental_history_na}
                          onChange={(e) => handleChange(e, "dental_history_na")}
                        />
                        <Box style={{ margin: "1rem 0" }}>
                          <Typography className={classes.secTitle}>{t("pre-treatment-procedures-title")}</Typography>
                          {data?.list_of_procedures &&
                            data.list_of_procedures.map((proc, key) => (
                              <div key={key} className={classes.procWrapper}>
                                <TextareaAutosize
                                  disabled={clinicImpersonated()}
                                  placeholder={t("add-procedure")}
                                  className={classes.textarea}
                                  style={{ width: "85%" }}
                                  value={proc}
                                  onChange={(e) =>
                                    handleChangeProc(
                                      e.target.value,
                                      key,
                                      "list_of_procedures"
                                    )
                                  }
                                />
                                {key === data?.list_of_procedures.length - 1 && (
                                  <Button
                                    disabled={clinicImpersonated()}
                                    onClick={addRow}
                                    className={classes.add}
                                  >
                                    {t("pre-treatment-procedures-add")}
                                  </Button>
                                )}
                              </div>
                            ))}
                        </Box>
                      </>
                    }
                    {
                      data?.clinical_findings_v2?.length > 0 && !data.clinical_findings_v2.find(f => f.condition === 'none') &&
                      <div className="pre-treatment-procedures">
                        <Typography className='treatment-approach-title'>{t('procedures-to-be-done-before-treatment-checkbox')}</Typography>
                        <RadioGroup name='pre-treatment-ans' className='info-arches-container'>
                          <InputLabel className='info-arches-InputLabel'>
                            <Radio onClick={handleCannotProceedChange} value={1} checked={cannotProceed} />
                            {t('Yes')}
                          </InputLabel>
                          <InputLabel className='info-arches-InputLabel'>
                            <Radio onClick={handleCannotProceedChange} value={0} checked={cannotProceed === false} />
                            {t('No')}
                          </InputLabel>
                        </RadioGroup>
                      </div>
                    }
                    {
                      cannotProceed &&
                      <Box className={`${classes.fullWidth} ${classes.flexBox} ${classes.findingsBox}`}>
                        <Box>
                          <Typography className={classes.secTitle}>{t('pre-treatment-procedures')}</Typography>
                        </Box>
                        <Box className={classes.colTitles}>
                          <Typography className={classes.colTitle}>{t('mention-procedures-to-be-done-before-treatment')}</Typography>
                          <Typography className={classes.redColTitle}>{parse(t('mention-teeth'))}</Typography>
                        </Box>
                        {
                          clinicalFindings.filter(finding => data?.clinical_findings_v2?.find(f => f?.condition === finding.value)).map((finding, key) => {
                            let clinicalFinding = data?.clinical_findings_v2?.find(f => f?.condition === finding.value);
                            let procedures = clinicalFinding?.procedures ? clinicalFinding.procedures : '';
                            return (
                              <Box className={classes.findingBox} key={key}>
                                <Typography style={{ color: "#363636" }}>{t(finding.label)}</Typography>
                                <TextField
                                  className={`${classes.textField} info-v2-textfield`}
                                  value={procedures}
                                  onChange={(e) =>
                                    handleChangeV2(e.target.value, finding.value, 'clinical_findings_v2', 'procedures')
                                  }
                                />
                              </Box>
                            )
                          })
                        }
                      </Box>
                    }
                    <Typography className={classes.generalErrorMsg1}>
                      {errorMsg.general}
                    </Typography>
                    <Typography className={classes.errorMsg}>
                      {errorMsg.list_of_procedures}
                    </Typography>
                  </Box>
                  <Box className={classes.card}>
                    <h3 className={classes.h3}>{t('aligner-treatment-preference')}</h3>
                    <TreatmentApproach
                      t={t}
                      infoDetails={infoDetails}
                      data={data}
                      handleRadioButtonClick={handleRadioButtonClick}
                    />
                    <br />
                    <AdditionalInstructions
                      t={t}
                      ids={teethIds}
                      handleClick={handleToothClick}
                      onTagClick={onTagClick}
                    />
                  </Box>
                </> :
                <>
                  <Box style={{ marginBottom: "1rem" }}>
                    <Typography className={classes.secTitle}>{t("Date-of-Birth")}</Typography>
                    {clinicImpersonated() ? (
                      <Box className={`${classes.viewWidth}`}>
                        <Typography className={classes.viewOnlyDate}>
                          {dateOfBirth ? dateOfBirth.format("MMMM") : "Month"}
                        </Typography>
                        <Typography className={classes.viewOnlyDate}>
                          {dateOfBirth ? dateOfBirth.format("DD") : "Date"}
                        </Typography>
                        <Typography className={classes.viewOnlyDate}>
                          {dateOfBirth ? dateOfBirth.format("YYYY") : "Year"}
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        className={`${classes.dateSelectoreWidth} ${classes.flexBox}}`}
                      >
                        <SelectDatePicker
                          minDate={new Date(1940, 1, 1)}
                          className={"date-time-picker"}
                          value={
                            information?.date_of_birth
                              ? new Date(data?.date_of_birth)
                              : null
                          }
                          onDateChange={(e) => handleChange(e, "date_of_birth")}
                        />
                      </Box>
                    )}
                  </Box>
                  <br />

                  <Box>
                    <Typography className={classes.secTitle}>{parse(t("What-problem-are-you-looking-to-get-fixed?"))}</Typography>
                    <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
                      <TextareaAutosize
                        disabled={clinicImpersonated()}
                        placeholder={t("Add-comment...")}
                        className={classes.textarea}
                        minRows={6}
                        value={data?.problem_question ? data.problem_question : ''}
                        onChange={(e) =>
                          handleChange(e.target.value, "problem_question")
                        }
                      />
                    </Box>
                  </Box>
                  {errorMsg?.problem_question && (
                    <Typography className={classes.errorMsg}>
                      {errorMsg.problem_question}
                    </Typography>
                  )}
                  <br />

                  <Box style={{ marginBottom: "1rem" }}>
                    <Typography className={classes.secTitle}>{t("Any-other-dental-history-we-should-know-about?")}</Typography>
                    <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
                      <TextareaAutosize
                        disabled={clinicImpersonated()}
                        placeholder={t("Add-comment...")}
                        className={classes.textarea}
                        minRows={6}
                        value={data?.dental_history ? data.dental_history : ''}
                        onChange={(e) =>
                          handleChange(e.target.value, "dental_history")
                        }
                      />
                    </Box>
                  </Box>
                  <Typography className={classes.errorMsg}>
                    {errorMsg.dental_history}
                  </Typography>

                  <Checkbox
                    label={t("pre-treatment-procedures-check")}
                    checked={data?.dental_history_na}
                    onChange={(e) => handleChange(e, "dental_history_na")}
                  />
                  {
                    data?.dental_history_na && data?.list_of_procedures?.length &&
                    <Box style={{ margin: "1rem 0" }}>
                      <Typography className={classes.secTitle}>{t("pre-treatment-procedures-title")}</Typography>
                      {
                        data.list_of_procedures.map((proc, key) => (
                          <div key={key} className={classes.procWrapper}>
                            <TextareaAutosize
                              disabled={clinicImpersonated()}
                              placeholder={t("add-procedure")}
                              className={classes.textarea}
                              style={{ width: "85%" }}
                              value={proc}
                              onChange={(e) =>
                                handleChangeProc(
                                  e.target.value,
                                  key,
                                  "list_of_procedures"
                                )
                              }
                            />
                            {key === data?.list_of_procedures.length - 1 && (
                              <Button
                                disabled={clinicImpersonated()}
                                onClick={addRow}
                                className={classes.add}
                              >
                                {t("pre-treatment-procedures-add")}
                              </Button>
                            )}
                          </div>
                        ))
                      }
                    </Box>
                  }
                  <br />
                  <br />

                  <Typography className={classes.secTitle}>{t('pain-symtpoms')}</Typography>
                  <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
                    <TextareaAutosize
                      disabled={clinicImpersonated()}
                      placeholder={t("Add-comment...")}
                      className={classes.textarea}
                      minRows={6}
                      value={data?.pain_symptoms ? data.pain_symptoms : ''}
                      onChange={(e) =>
                        handleChange(e.target.value, "pain_symptoms")
                      }
                    />
                  </Box>
                  <br />
                </>
              }
              <Button
                disabled={clinicImpersonated()}
                className={classes.saveBtn}
                onClick={handleSave}
              >
                {success ? t("Saved") : t("Save")}
              </Button>
              <Typography className={classes.generalErrorMsg}>
                {errorMsg.general}
              </Typography>
            </>
          )}
        </Box>
      )}
    </ProcedureHeader>
  );
}
