import { Box, Chip, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Cookies from "js-cookie";
import moment from 'moment';
import { Translate } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { TableAppointmentsStatues } from "../../util/constants/table-actions.constants";
const useStyles = makeStyles(() => { return {
    fontFamilyAlmarai:{
        fontFamily:'Almarai-semi !important'
    },
    fontFamilyEina: {
        fontFamily: "Eina-semi !important",
    },
    root:{
        backgroundColor:"rgb(246, 246, 247)",
    },
    wrapper:{
        backgroundColor:'#FFF',
        flex: 1,
        padding:'1rem',
        borderRadius: '3px',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
        ['@media only screen and (max-width: 1170px) '] : {
            width: '100%'
           },
    },
    typoColor:{
        color: '#637381',
    },
    header:{
        fontSize: '18px !important',
        letterSpacing: 0,
        lineHeight: '32px',
        minHeight: '32px'
    },
    heightMD:{
        margin: '0.5rem 0',
    },
    bold:{
        // fontFamily: "Eina-bold !important",
        fontWeight:'bold',
    },
    thin:{
        // fontFamily: "Eina-light !important"
        fontWeight:'500',
    },
    clinicName:{
        fontSize: '14px !important',
        letterSpacing: 0,
        lineHeight: '20px',
    },
    dotStyle:{
        backgroundColor: '#637381',
        height: '0.2rem',
        width: '0.2rem',
        borderRadius: '50%',
        display: 'inline-block',
        marginLeft:'0.1rem'
    },
    pending:{
      color: '#fff',
      backgroundColor: '#87abff',
      height:20
    },
    completed:{
        color: '#fff',
        backgroundColor: '#98bae7',
        height:20

    },
    confirmed: {
        color: '#fff',
        backgroundColor: '#45da10',
        height:20,
    },
    qualified: {
        color: '#fff',
        backgroundColor: '#8ee2ed',
        height:20,
    },
    cancelled: {
        color: '#fff',
        backgroundColor: 'red',
        height:20,
    },
    noShowUp: {
        color: '#fff',
        backgroundColor: '#FFA500',
        height:20,
    },
    noAnswer: {
        color: '#fff',
        backgroundColor: '#FFA500',
        height:20,
    },
    unqualified: {
        color: '#fff',
        backgroundColor: '#65737E',
        height:20,
    },
    postponed: {
        color: '#fff',
        backgroundColor: '#f95518',
        height: 20,
    },
}});
export default function BookingInformation(props) {
    const {profileResponse , action} = props;
    const { t, i18n } = useTranslation()
    const classes = useStyles();
    const [date, setDate]= useState('');
    const [time, setTime]= useState('');

    const handleStatus = (value) => {
      if(value === 'Completed' || value === TableAppointmentsStatues.APPOINTMENT_DONE_ELSEWHERE){
          return <Chip className={classes.completed} label={t(value)}  />
      } else if(value === 'Pending' || value === TableAppointmentsStatues.NEEDS_RESCHEDULING){
          return  <Chip className={classes.pending} label={t(value)}  />
      } else if(value === 'Confirmed'){
          return <Chip className={classes.confirmed} label={t(value)} />
      } else if(value === 'Qualified'){
          return <Chip className={classes.qualified} label={t(value)}  />
      } else if(value === 'Cancelled'){
          return <Chip className={classes.cancelled} label={t(value)} />
      }else if(value === 'No Show'){
          return <Chip className={classes.noShowUp} label={t(value)} />
      }else if(value === 'No Answer'){
          return <Chip className={classes.noAnswer} label={t(value)} />
      } else if(value === 'Unqualified'){
        return <Chip className={classes.unqualified} label={t(value)} />
      } else if(value === 'Postponed'){
        return <Chip className={classes.postponed} label={t(value)} />
      }
    }

    useEffect(()=>{
        let formatDate = profileResponse?.appointment_details?.date?.split(' ');
        if(formatDate?.length > 2){
            setTime(`${formatDate[1]}${formatDate[2]}`)
            // formatDate = formatDate[0].split('/');
            // if(formatDate.length > 2){
            //     formatDate = `${formatDate[1]}-${formatDate[0]}-${formatDate[2]}`;
            // }
            // setDate(moment(new Date(formatDate)).format('ddd DD/MM/YYYY'));
            setDate(profileResponse?.formatted_date);
        }
    }, [])

  return (
      <Box className={classes.wrapper}>
          <Typography  className={`${classes.header} ${classes.typoColor} ${classes.bold} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{t('Booking-Information')}</Typography>
          <Typography  className={`${classes.clinicName} ${classes.heightMD} ${classes.thin} ${classes.typoColor} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{profileResponse?.book_scan_info?.clinic?.name?.en}</Typography>
          {/* <Typography   className={`${classes.clinicName} ${classes.heightMD} ${classes.thin} ${classes.typoColor}`}>{profileResponse?.book_scan_info?.clinic?.address?.en}</Typography> */}
          {profileResponse?.appointment_details?.preferred_date && <Typography   className={`${classes.clinicName} ${classes.heightMD} ${classes.thin} ${classes.typoColor} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{t('Preferred-Date')} {profileResponse?.appointment_details?.preferred_date} </Typography>}
          {profileResponse?.appointment_details?.preferred_time && <Typography   className={`${classes.clinicName} ${classes.heightMD} ${classes.thin} ${classes.typoColor} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{t('Preferred-Time')} {profileResponse?.appointment_details?.preferred_time} </Typography>}
          {profileResponse?.appointment_details?.completed_appointment_date && <Typography   className={`${classes.clinicName} ${classes.heightMD} ${classes.thin} ${classes.typoColor} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{t('Latest-Scan-Completed-Appointment-Date')}{profileResponse?.appointment_details?.completed_appointment_date} </Typography>}
          {profileResponse?.appointment_details?.completed_procedure_date && <Typography   className={`${classes.clinicName} ${classes.heightMD} ${classes.thin} ${classes.typoColor} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{t('Latest-Procedures-Completed-Appointment-Date')}{profileResponse?.appointment_details?.completed_procedure_date} </Typography>}
          {!date && !time && 
                <Box style={{display:'flex',gap: '1rem', alignItems:'center'}}>
                    <Typography className={`${classes.clinicName} ${classes.heightMD} ${classes.thin} ${classes.typoColor} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>
                        {t('Status:')}
                    </Typography> 
                    {handleStatus(action)}
                </Box>}
          {date && time &&  (
                <Box style={{display:'flex', justifyContent:'space-between', width:'90%', alignItems:'center'}}>
                  <Typography className={`${classes.clinicName} ${classes.heightMD} ${classes.thin} ${classes.typoColor} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>
                    {t('Latest Appointment Date:')} {date} <span className={classes.dotStyle}></span> {time}
                  </Typography>
                  <Box>{handleStatus(action)}</Box>
                </Box>
            )}
    </Box>
  )
}
